// -- React and related libs
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom"

// -- Redux
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { Provider } from 'react-redux';
import reducers from './reducers';

// -- App
import App from './App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// -- Data Store
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(ReduxThunk)),
);

// -- Rendering Application
render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);


