import { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";


export default function ApexActivityChart({ data, period }) {
  const [series, setSeries] = useState([{
    name: 'Your Activity',
    type: 'column',
    data: [0, 0, 0, 0, 0, 0, 0],
    color: "#0D3991"
  }]);
  
  let categories = period === 'daily' ? 
    ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']
    :
    ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const statsDays = data.map(stat => stat.time);

    let handledSeries = categories.map(category => {
      const idx = statsDays.indexOf(category);
      if (idx > -1) return data[idx].times;
      return 0;
    });

    setSeries([{
      name: 'Your Activity',
      type: 'column',
      data: handledSeries,
      color: "#0D3991"
    }])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  var chartSettings = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    
    xaxis: {
      categories,
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + " times";
        }
      }
    
    }
  }

  return (
    <ApexCharts
      options={chartSettings}
      series={series}
      type="bar"
      height={350}
    />
  )
}
