import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";
import { ToastContainer } from 'react-toastify';
import { Skeleton } from "@material-ui/lab";
import { Row, Col, Button } from "reactstrap";
import Swal from 'sweetalert2'
import Widget from "../../components/Widget/Widget.js";
import RegisterActivationModal from "./Modals/registerActivationModal"

import s from "./ManageActivations.module.scss";
import { Tooltip } from "@material-ui/core";

const ManageActivations = function ({ user }) {
  const [fetching, setFetching] = useState(true);
  const [availableActivations, setAvailableActivations] = useState(user.availableActivations);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activations, setActivations] = useState([]);

  const getActivations = async () => {
    const { data: { success: allActivations , err } } = await axios.get(`/activations`);

    if (err) return Toast({ type: 'error', content: err });

    setActivations(allActivations);
    setFetching(false);
  }

  useEffect(() => {
    getActivations();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(prev => !prev)
  }

  const addNewActivation = (activationForm) => {
    setActivations(prev => prev.concat(activationForm));
    setAvailableActivations(availableActivations - 1);
  }

  const resetActivation = async (activationId) => {
    const { isConfirmed } = await Swal.fire({
      text: 'Are you sure you want to reset this activation ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Reset',
    });
    
    if (isConfirmed) {
      const { data: { success: activation , err } } = await axios.put(`/activations?activationId=${activationId}`);
  
      if (err) return Toast({ type: 'error', content: err });
  
      setActivations(prev => prev.map(actvtion => {
        if (actvtion._id === activationId) return activation;
        return actvtion;
      }));
  
      Toast({ type: 'success', content: 'Activation reset successfully' });
    }
  }

  const deleteActivation = async (activationId) => {
    const { isConfirmed } = await Swal.fire({
      text: 'Are you sure you want to delete this activation ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });
    
    if (isConfirmed) {
      const { data: { err } } = await axios.delete(`/activations?activationId=${activationId}`);

      if (err) return Toast({ type: 'error', content: err });

      setActivations(prev => prev.filter(actvtion => actvtion._id !== activationId));

      setAvailableActivations(prev => prev + 1);

      Toast({ type: 'success', content: 'Activation deleted successfully' });
    }
  }

  return (
    <>
      <div className={s.top_panel}>
        <div className={s.remaining_activations}>
          <i className="eva eva-activity-outline"></i>
          <p>Available Activations <span>{ availableActivations }</span></p>
        </div>
        {
          availableActivations > 0 && (
            <div className={s.add_new_activation}>
              <Button onClick={() => setIsModalOpen(true)}>
                <i className="eva eva-plus-circle-outline"></i>
                Register Activation
              </Button>
            </div>
          )
        }
      </div>
      <div>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <Widget>
                  <div className={s.tableTitle}>
                    <div className="headline-2">Manage Activations</div>
                  </div>
                  <Col xs={12} className="pr-grid-col">
                    <Widget>
                      <div className={s.widgetContentBlock}>
                        
                        <div className={`${s.content} ${s.head}`}>
                          <div><span className="body-2 ml-3">Activation Serial Code</span></div>
                          <div className="body-3 muted d-none d-md-block">Device Mac Address</div>
                          <div className="body-2">Activation Date</div>
                          <div className="body-3 muted d-none d-lg-block">Register Date</div>
                          <div className="body-3 muted d-none d-lg-block">Statue</div>
                          <div>
                            <i className="eva eva-edit-2-outline"></i>
                          </div>

                        </div>
                        {
                          activations.length ? activations.map(activation => (
                            <div key={uuidv4()} className={s.content}>
                              <div><span className="body-2 ml-3">{activation.serialCode}</span></div>
                              <div className="body-3 muted d-none d-md-block text-uppercase">{activation.macAddress}</div>
                              <div className="body-2">{activation.activationDate}</div>
                              <div className="body-3 muted d-none d-lg-block">{activation.registerationDate}</div>
                              <div className="body-3 muted d-none d-lg-block"><span className={activation.statue === "Active" ? s.active : ''}>{activation.statue}</span></div>
                              <div>
                                <Tooltip title="Reset Activation" aria-label="Reset Activation">
                                  <i className="eva eva-refresh-outline" onClick={() => resetActivation(activation._id)} />
                                </Tooltip>
                                <Tooltip title="Delete" aria-label="Delete">
                                  <i className="eva eva-trash-outline" onClick={() => deleteActivation(activation._id)} />
                                </Tooltip>
                              </div>
                            </div>
                          )) 
                          :
                          (
                            fetching ?
                            <>
                              <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={60} />
                              <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={60} />
                            </>
                            :
                            <div className={`${s.content} ${s.no_activation}`}>
                              <p>No Activations Yet</p>
                            </div>
                          )
                        }
                      </div>
                    </Widget>
                  </Col>
                </Widget>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <RegisterActivationModal toggle={toggleModal} isOpen={isModalOpen} availableActivations={availableActivations} addNewActivation={addNewActivation} />
      <ToastContainer />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(ManageActivations);
