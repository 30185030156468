import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Button, FormGroup, FormText, Input } from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import { setUser, receiveLogin } from "../../actions/auth";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";

import loginImage from "../../assets/icon.png";
import EduLogo from "../../components/Icons/EduLogo.js";

const Login = ({ dispatch }) => {

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const doLogin = async (e) => {
    e.preventDefault();
    
    const { data: { success, err } } = await axios.post("/auth", credentials);
    
    if (err) return Toast({ type: 'error', content: err });

    localStorage.setItem('eduems-dashboard-login-session', success.token);
  
    dispatch(receiveLogin());

    dispatch(setUser(success.user));

    Toast({ type: 'success', content: `Welcome back, ${success.user.username}` });
  }

  const changeCreds = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  return (
    <div className="auth-page">
      <Container className="col-12 h-100">
        <Row className="d-flex align-items-center h-100">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg rounded">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0 font-weight-bold">LOGIN</p>
                <div className="logo-block">
                  <EduLogo />
                </div>
              </div>
              <div className="auth-info my-2">
                <p>Edu. EMS - Admin <br/> <b>Use your credientials</b> to login!</p>
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={credentials.email}
                    onChange={(event) => changeCreds(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="example@email.com"
                  />
                </FormGroup>
                <FormGroup  className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText>Password</FormText>
                    <Link to="/forget">Forgot password?</Link>
                  </div>
                  <Input
                    id="password"
                    className="input-transparent pl-3"
                    value={credentials.password}
                    onChange={(event) => changeCreds(event)}
                    type="password"
                    required
                    name="password"
                    placeholder="your password"
                  />
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button className="rounded-pill my-3" type="submit" color="secondary-red">Login</Button>
                </div>
                <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                <a href="https://edu-ems.cf/#Contact" target="_blank" rel="noreferrer">Don’t have an account? Request account here</a>
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column text-center">
            <div>
              <img src={loginImage} alt="login page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}


Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    user: state.auth.user
  };
}

export default withRouter(connect(mapStateToProps)(Login));
