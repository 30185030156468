// -- React and related libs
import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Dashboard from "../../pages/dashboard/Dashboard";
import Account from "../../pages/account/Account";
import ManageActivations from "../../pages/manageActivations/ManageActivations";
import RequestActivation from "../../pages/requestActivation/RequestActivation";
import ManageClients from "../../pages/manageClients/ManageClients";
import CreateClient from "../../pages/createClient/CreateClient";
import ProgramRequests from "../../pages/programRequests/ProgramRequests";
import ActivationsRequests from "../../pages/activationsRequests/ActivationsRequests";

// -- Component Styles
import s from "./Layout.module.scss";

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          {
            props.location.pathname !== "/" && <Breadcrumbs url={props.location.pathname} />
          }
          <Switch>
            <Route path="/" exact render={() => <Redirect to={"/dashboard"} />} />
            <Route path="/dashboard" exact component={Dashboard}/>
            <Route path="/account" exact component={Account} />
            <Route path="/activations" exact render={() => <Redirect to={"/activations/manage"} />} />
            <Route path="/activations/manage" exact component={ManageActivations} />
            <Route path="/activations/request" exact component={RequestActivation} />
            {
              props.user?.role === 'admin' &&
              (
                <>
                  <Route path="/clients" exact render={() => <Redirect to={"/activations/manage"} />} />
                  <Route path="/clients/manage" exact component={ManageClients} />
                  <Route path="/clients/create" exact component={CreateClient} />
                  <Route path="/requests" exact render={() => <Redirect to={"/requests/program"} />} />
                  <Route path="/requests/program" exact component={ProgramRequests} />
                  <Route path="/requests/activations" exact component={ActivationsRequests} />
                </>
              )
            }
            <Route path='*' exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    user: store.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
