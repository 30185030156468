import React from "react";
import s from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>2021 &copy; Hassan Ali</span>
    </div>
  )
}

export default Footer;
