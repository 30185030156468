import React from 'react';
import Logo from "../../assets/icon.png"

class EduLogo extends React.Component {

  render() {
    return (
      <img src={Logo} alt="logo" width={40} height={40} />
    );
  }
}

export default EduLogo;
