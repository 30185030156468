// -- React and related libs
import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// -- Redux
import { connect } from "react-redux";

// -- Custom Components
import LayoutComponent from "./components/Layout/Layout";
import ErrorPage from "./pages/error/ErrorPage";
import Login from "./pages/login/Login";
import Forget from "./pages/forget/Forget";
import Reset from "./pages/reset/Reset";
import Loading from "./components/Loading/Loading";

// -- Third Party Libs
import { ToastContainer } from "react-toastify";

// -- Services
import checkAuthentication from "./services/authService";

// -- Component Styles
import "./styles/app.scss";

const App = ({ dispatch, isAuthenticated: authenticated }) => {
  const [loading, setLoading] = useState(true);

  const checkAuth = async () => {
    await checkAuthentication(dispatch);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    checkAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  return (
    <div>
      <ToastContainer/>
      {
        loading
        ? 
        <Loading />
        :
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login}>
              {
                (!loading && authenticated) && <Redirect to="/" />
              }
            </Route>
            <Route path="/forget" exact component={Forget}>
              {
                (!loading && authenticated) && <Redirect to="/" />
              }
            </Route>
            <Route path="/reset" exact component={Reset}>
              {
                (!loading && authenticated) && <Redirect to="/" />
              }
            </Route>
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/" component={LayoutComponent}>
              {
                (!loading && !authenticated) && <Redirect to="/login" />
              }
            </Route>
            <Route render={() => <Redirect to="/error" />} />
          </Switch>
        </BrowserRouter>
      }
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
