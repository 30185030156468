import {
  SET_USER,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
} from "../actions/auth.js";

const authenticated = localStorage.getItem('eduems-dashboard-login-session');

export default function auth(state = {
  isFetching: false,
  isAuthenticated: authenticated,
  user: null,
  errorMessage: ''
}, action) {
  switch (action.type) {
    case SET_USER: {
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        user: action.user,
        errorMessage: '',
      });
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
      });
    }
    case LOGIN_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        user: null,
        errorMessage: action.err,
      });
    }
    case LOGOUT_SUCCESS: {
      return Object.assign({}, state, {
        user: null,
        isAuthenticated: false,
        errorMessage: '',
      });
    }
    default:
      return state;
  }
}
