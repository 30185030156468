import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Button, FormGroup, FormText, Input } from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";

import resetImage from "../../assets/icon.png";
import EduLogo from "../../components/Icons/EduLogo.js";

const Forget = (props) => {
  const [credentials, setCredentials] = useState({ email: '' } )

  const changeCred = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  const doReset = async (e) => {
    e.preventDefault();
    
    const { data: { err } } = await axios.post("/auth/reset", credentials);
    
    if (err) return Toast({ type: 'error', content: err });

    Toast({ type: 'success', content: `password reset email sent to your email address` });
  }

  return (
    <div className="auth-page">
      <Container className="col-12 h-100">
        <Row className="d-flex align-items-center h-100">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg rounded">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Forget Password</p>
                <div className="logo-block">
                  <EduLogo />
                </div>
              </div>
              <div className="auth-info my-2">
                <p>Edu. EMS - Admin <br/> <b>Use your email</b> to reset your password!</p>
              </div>
              <form onSubmit={(e => doReset(e))}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={credentials.email}
                    onChange={(e) => changeCred(e)}
                    type="email"
                    required
                    name="email"
                    placeholder="example@email.com"
                  />
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button className="rounded-pill my-3" type="submit" color="secondary-red">Reset Password</Button>
                </div>
                <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                <Link to="/login">Login to your account</Link>
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={resetImage} alt="reset page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

Forget.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Forget));
