import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
import dayjs from "dayjs";
import { Col, Row, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { Skeleton } from "@material-ui/lab";
import Widget from "../../components/Widget/Widget.js";
import ApexActivityChart from "./components/ActivityChart.js";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";

import EduImage from "../../assets/app.png";

import s from "./Dashboard.module.scss";

/* eslint-disable react-hooks/exhaustive-deps */

const Dashboard = () => {
  const [logs, setLogs] = useState([]);
  const [stats, setStats] = useState([]);
  const [logsRange, setLogsRange] = useState({ range: '1', title: 'Today' });
  const [logsChartPeriod, setLogsChartPeriod] = useState('daily');

  const getLogs = async () => {
    const { data: { success: logs , err } } = await axios.get(`/logs/range-stats?range=${logsRange.range}`);

    if (err) return Toast({ type: 'error', content: err });

    const formatLogs = logs.map(log => ({
      location: log.location,
      actionType: log.type,
      time: dayjs(log.createdAt).format("DD/MM/YYYY hh:mm a")
    }))

    setLogs(formatLogs);
  }

  const getStats = async () => {
    const { data: { success: Stats , err } } = await axios.get(`/logs/period-stats?period=${logsChartPeriod}`);

    if (err) return Toast({ type: 'error', content: err });

    setStats(Stats);

    console.log(stats);
  }

  useEffect(() => {
    getLogs();
  }, [logsRange]);

  useEffect(() => {
    getStats();
  }, [logsChartPeriod]);

  const showFeatures = () => {
    window.open("https://edu-ems.cf/#Features", "_blank")
  }

  const showContactUs = () => {
    window.open("https://edu-ems.cf/#Contact", "_blank")
  }

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12}>
          <Row className="gutter mb-4">
            {/* ACTIVITY CHART */}
            <Col className="mb-4 mb-md-0" xs={12} md={6}>
              <Widget className="">
                <div className="d-flex justify-content-between widget-p-md">
                  <div className="headline-3 d-flex align-items-center">Your activity</div>
                  <UncontrolledDropdown>
                    <DropdownToggle caret style={{textTransform: 'capitalize'}}>
                      &nbsp; { logsChartPeriod } &nbsp;
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setLogsChartPeriod('daily')}>Daily</DropdownItem>
                      <DropdownItem onClick={() => setLogsChartPeriod('monthly')}>Monthly</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <ApexActivityChart data={stats} period={logsChartPeriod} className="pb-4" />
              </Widget>
            </Col>
            {/* USAGES */}
            <Col xs={12} md={6}>
              <Widget className={`widget-p-md ${s.usages_wedgit}`}>
                <div className="d-flex justify-content-between">
                  <div className="headline-3 d-flex align-items-center">Your Logs</div>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      &nbsp; { logsRange.title } &nbsp;
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setLogsRange({ range: '1', title: 'Today' })}>Today</DropdownItem>
                      <DropdownItem onClick={() => setLogsRange({ range: '7', title: 'This Week' })}>This Week</DropdownItem>
                      <DropdownItem onClick={() => setLogsRange({ range: '30', title: 'This Month' })}>This Month</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className={s.usages}>
                  {
                    logs.length ? logs.map(log => (
                      <div className={s.usage} key={uuidv4()}>
                        <span style={{ textTransform: 'uppercase' }}>
                          <i className="eva eva-radio-button-on-outline"></i>
                          { log.actionType }
                        </span>
                        <span>
                          <i className="eva eva-pin-outline"></i>
                          { log.location?.city }, { log.location?.country } | { log.location?.query }
                        </span>
                        <span>
                          <i className="eva eva-clock-outline"></i>
                          { log.time }
                        </span>
                      </div>

                    ))
                    :
                    (
                      <>
                        <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={52} />
                        <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={52} />
                        <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={52} />
                        <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={52} />
                        <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={52} />
                        <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={52} />
                      </>
                    )
                  }
                </div>
              </Widget>
            </Col>
          </Row>
          <Row className="gutter mb-4">
            <Col xs={12}>
              <Widget className="widget-p-none">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <div className="d-flex flex-column align-items-center col-12 col-xl-6 p-sm-4">
                    <p className="headline-1">Edu. EMS System</p>
                    <p className="body-3">The Best Educational Management System</p>
                    <div className="d-flex justify-content-between my-4">
                      <Button className="rounded-pill mr-3" color="primary" onClick={showFeatures}>Explore Features</Button>
                      <Button className="rounded-pill body-3" outline color="dark" onClick={showContactUs}>Request your own version</Button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center col-12 col-xl-6">
                    <img className={`p-1 img-fluid ${s.eduImage}`} src={EduImage} alt="..." />
                  </div>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard;
