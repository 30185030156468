import React, { useState } from "react";
import { Row, Col, InputGroup, Input, Button, Label } from "reactstrap";
import { connect } from "react-redux";
import dayjs from "dayjs";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";
import { setUser } from "../../actions/auth";
import cls from "./Account.module.scss"

const Account = ({ user, dispatch }) => {
  const [loadingInfoFromChange, setLoadingInfoFromChange] = useState(false);
  const [loadingPasswordFromChange, setLoadingPasswordFromChange] = useState(false);

  const [infoForm, setInfoForm] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    username: user?.username,
    email: user?.email,
    phone: user?.phone,
    address: user?.address,
  });

  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleInfoFormChange = e => setInfoForm(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const handlePasswordFormChange = e => setPasswordForm(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const submitInfoChange = async () => {
    setLoadingInfoFromChange(true);

    const { data: { success: updatedUser, err } } = await axios.put(`/users/change-info?userId=${user._id}`, infoForm);

    if (err) {
      setLoadingInfoFromChange(false);
      return Toast({ type: 'error', content: err });
    }

    dispatch(setUser(updatedUser))

    Toast({ type: 'success', content: 'Your account info changed successfully' });

    setLoadingInfoFromChange(false);
  }

  const submitPasswordChange = async () => {
    setLoadingPasswordFromChange(true);

    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      setLoadingPasswordFromChange(false);
      return Toast({ type: 'error', content: `New password doesn't match confirm password` });
    }

    const { data: { err } } = await axios.put(`/users/change-password?userId=${user._id}`, passwordForm);

    if (err) {
      setLoadingPasswordFromChange(false);
      return Toast({ type: 'error', content: err });
    }

    Toast({ type: 'success', content: 'Your account password changed successfully' });
    setPasswordForm({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    setLoadingPasswordFromChange(false);
  }

  return (
    <>
      <div className={cls.main_info}>
        <Row>
          <Col xs={12} md={6}>
            <div className={cls.info_peice}>
              <i className="eva eva-person-outline"></i>
              <span>{ user?.username }</span>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className={cls.info_peice}>
              <i className="eva eva-clock-outline" style={{ top: '5px' }}></i>
              <span>{ dayjs(user?.createdAt).format("DD/MM/YYYY hh:mm a") }</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className={cls.account}>
        <h4>Account Info</h4>
        <Row>
          <Col xs={12} md={6}>
            <Label>First Name</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-alert-circle-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Firstname"
                type="text"
                name="firstName"
                value={infoForm.firstName}
                onChange={handleInfoFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Last Name</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-alert-circle-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Lastname"
                type="text"
                name="lastName"
                value={infoForm.lastName}
                onChange={handleInfoFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Username</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-person-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Username"
                type="text"
                name="username"
                value={infoForm.username}
                onChange={handleInfoFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Email Address</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-email-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Email Address"
                type="email"
                name="email"
                value={infoForm.email}
                onChange={handleInfoFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Contact Number</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-smartphone-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Contact Number"
                type="tel"
                name="phone"
                value={infoForm.phone}
                onChange={handleInfoFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Address</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-pin-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Address"
                type="address"
                name="address"
                value={infoForm.address}
                onChange={handleInfoFormChange}
              />
            </InputGroup>
          </Col>
        </Row>
        <Button onClick={submitInfoChange} disabled={loadingInfoFromChange}>
          {
            loadingInfoFromChange ? "Saving..." : "Save"
          }
        </Button>
        <hr/>
        <h4>Security Settings</h4>
        <Row>
          <Col xs={12}>
            <Label>Current Password</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-lock-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Current Password"
                type="password"
                name="currentPassword"
                value={passwordForm.currentPassword}
                onChange={handlePasswordFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>New Password</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-unlock-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="New Password"
                type="password"
                name="newPassword"
                value={passwordForm.newPassword}
                onChange={handlePasswordFormChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Confrim Password</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-unlock-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                value={passwordForm.confirmPassword}
                onChange={handlePasswordFormChange}
              />
            </InputGroup>
          </Col>
        </Row>
        <Button onClick={submitPasswordChange} disabled={loadingPasswordFromChange}>
          {
            loadingPasswordFromChange ? "Saving..." : "Save"
          }
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(Account);
