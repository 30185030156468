import axios from "axios"

axios.defaults.baseURL = 'https://edu-ems-dashboard-api.herokuapp.com/api/v1'
// axios.defaults.baseURL = 'http://localhost:5000/api/v1'

export const setHeadersAuthToken = (token) => axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export const removeHeadersAuthToken = () => axios.defaults.headers.common['Authorization'] = null;

export default axios;
