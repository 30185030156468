import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Button, FormGroup, FormText, Input } from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";

import resetImage from "../../assets/icon.png";
import EduLogo from "../../components/Icons/EduLogo.js";
import cls from "./Reset.module.scss";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Reset = (props) => {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [isValidData, setIsValidData] = useState(false);
  const [email, setEmail] = useState("");
  const [resetForm, setResetForm] = useState({ token: '', userId: '', newPassword: '', confirmPassword: '' });

  const validateToken = async () => {
    const token = query.get('t');

    if (!token) {
      return setLoading(false)
    }
    
    const { data: { success: user, err } } = await axios.post("/auth/validate", { token });
    
    if (err)  {
      return setLoading(false)
    }

    setEmail(user.email);
    setResetForm(prev => ({
      ...prev,
      token,
      userId: user._id
    }))
    setIsValidData(true);
    setLoading(false);
  }

  useEffect(() => {
    validateToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const changeCred = (e) => {
    setResetForm({ ...resetForm, [e.target.name]: e.target.value })
  }

  const doReset = async (e) => {
    e.preventDefault();

    if (resetForm.newPassword !== resetForm.confirmPassword) return Toast({ type: 'error', content: `Passwords doesn't match` });
    
    const { data: { err } } = await axios.put("/auth/verify-change", resetForm);
    
    if (err) return Toast({ type: 'error', content: err });

    Toast({ type: 'success', content: `password reset successfully, please go to login page` });
  }

  return (
    <div className="auth-page">
      <Container className="col-12 h-100">
        <Row className="d-flex align-items-center h-100">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg rounded">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Reset Password</p>
                <div className="logo-block">
                  <EduLogo />
                </div>
              </div>
              <div className="auth-info my-2">
                <p>Edu. EMS - Admin <br/> Reset your password!</p>
              </div>
              {
                loading ?
                  <div className={cls.loading}>
                    <div className={cls["lds-facebook"]}><div></div><div></div><div></div></div>
                  </div>
                :
                isValidData 
                ?
                  (<form onSubmit={(e => doReset(e))}>
                    <FormGroup className="my-3">
                      <FormText>Email Address</FormText>
                      <Input
                        id="email"
                        className="input-transparent pl-3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                        name="email"
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup className="my-3">
                      <FormText>New Password</FormText>
                      <Input
                        id="newPassword"
                        className="input-transparent pl-3"
                        value={resetForm.newPassword}
                        onChange={(e) => changeCred(e)}
                        type="password"
                        required
                        name="newPassword"
                        placeholder="New Password"
                      />
                    </FormGroup>
                    <FormGroup className="my-3">
                      <FormText>Confirm Password</FormText>
                      <Input
                        id="confirmPassword"
                        className="input-transparent pl-3"
                        value={resetForm.confirmPassword}
                        onChange={(e) => changeCred(e)}
                        type="password"
                        required
                        name="confirmPassword"
                        placeholder="Confirm Password"
                      />
                    </FormGroup>
                    <div className="bg-widget d-flex justify-content-center">
                      <Button className="rounded-pill my-3" type="submit" color="secondary-red">Reset Password</Button>
                    </div>
                    <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                    <Link to="/login">Login to your account</Link>
                  </form>)
                :
                  <div className={cls.notValid}>
                    <i className="eva eva-alert-triangle-outline"></i>
                    <h3>Not Valid Reset Data</h3>
                    <Link to="/login">Return to login</Link>
                  </div>
              }
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={resetImage} alt="reset page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

Reset.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Reset));
