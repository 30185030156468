import React, { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import { Row, Col, Collapse, Card, CardHeader, CardBody } from "reactstrap";
import { Typography, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Widget from "../../components/Widget/Widget.js";
import dayjs from "dayjs";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";

import s from "./ProgramRequests.module.scss";

const ProgramRequests = function () {
  const [fetching, setFetching] = useState(true);
  const [requests, setRequests] = useState([]);

  const getRequests = async () => {
    const { data: { success: allRequests , err } } = await axios.get(`/requests/program`);

    if (err) return Toast({ type: 'error', content: err });

    const formattedRequests = allRequests.map(request => ({
      ...request,
      isDetailsOpened: false,
    }))

    setRequests(formattedRequests);
    setFetching(false);
  }

  useEffect(() => {
    getRequests();
  }, [])

  const openRequestCard = (reqId) => {
    setRequests(prev => prev.map(req => {
        if (req._id === reqId) return {
          ...req,
          isDetailsOpened: !req.isDetailsOpened
        }
        else return req;
      })
    )
  }

  const closeRequest = async (reqId) => {
    const { data: { success: Request , err } } = await axios.put(`/requests/program?requestId=${reqId}`);

    if (err) return Toast({ type: 'error', content: err });

    setRequests(prev => prev.map(request => {
      if (request._id === reqId) return Request;
      return request;
    }));

    Toast({type: 'success', content: `Request has been closed`})
  }

  return (
    <>
      <div className={s.top_panel}>
        <div className={s.all_requests}>
          <i className="eva eva-shopping-bag-outline"></i>
          <p>All Requests <span>{ requests.length }</span></p>
        </div>
      </div>
      <div>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <Widget>
                  <div className={s.tableTitle}>
                    <div className="headline-2">Program Requests</div>
                  </div>
                  <Col xs={12} className="pr-grid-col">
                    <Widget>
                      <div className={s.widgetContentBlock}>
                        <div className={`${s.content} ${s.head}`}>
                          <div className="body-3">Fullname</div>
                          <div className="d-none d-md-block"><span className="muted body-2 ml-3">Email Address</span></div>
                          <div><span className="muted body-2 ml-3">Statue</span></div>
                          <div>
                            <i className="eva eva-checkmark-circle-outline" style={{fontSize: '22px', position: 'relative', top: "5px", color: "#6b859e"}}></i>
                          </div>
                        </div>

                        {
                          requests.length ? requests.map(request => (
                            <Card key={request._id} className={s.request_card} style={{ cursor: "pointer" }}>
                              <CardHeader className={s.content}>
                                <div className="body-3" onClick={() => openRequestCard(request._id)}>{request.fullName}</div>
                                <div className="d-none d-md-block" onClick={() => openRequestCard(request._id)}><span className="muted body-2 ml-3">{request.emailAddress}</span></div>
                                <div onClick={() => openRequestCard(request._id)}>
                                  {
                                    request.statue === 'unhandled' ? 
                                    (
                                      <Tooltip title="Unhandled Request" aria-label="Unhandled Request">
                                        <i className="eva eva-minus-circle-outline" style={{color: 'red'}} />
                                      </Tooltip>
                                    )
                                    :
                                    (
                                      <Tooltip title="Closed Request" aria-label="Closed Request">
                                        <i className="eva eva-checkmark-circle-2-outline" style={{color: 'green'}} />
                                      </Tooltip>
                                    )
                                  }
                                </div>
                                <div>
                                  {
                                    request.statue === 'unhandled' ?
                                    (
                                      <Tooltip title="Close Request" aria-label="Close Request">
                                        <i className="eva eva-lock-outline" onClick={() => closeRequest(request._id)} />
                                      </Tooltip>
                                    )
                                    :
                                    "-"
                                  }
                                </div>
                              </CardHeader>
                              <Collapse isOpen={request.isDetailsOpened}>
                                <CardBody className={s.request_card__body}>
                                  <Typography variant="h6" component="h3">
                                    {
                                      request.statue === 'unhandled' ?
                                      (
                                        request.title 
                                      )
                                      :
                                      (
                                        <>
                                          <del>{ request.title }</del>
                                          <i className="eva eva-checkmark-circle-2-outline ml-2" style={{position: 'relative', top: '5px', color: 'green'}} />
                                        </>
                                      )
                                    }
                                  </Typography>
                                  <Typography variant="subtitle2" component="p">
                                    { request.details }
                                  </Typography>
                                  <hr/>
                                  <Typography variant="subtitle2" component="span">
                                    { request.fullName } | { request.emailAddress } | { request.phone }
                                  </Typography>
                                  <hr/>
                                  <Typography variant="subtitle2" component="span">
                                    { dayjs(request.createdAt).format("DD/MM/YYYY hh:mm a") }
                                  </Typography>
                                </CardBody>
                              </Collapse>
                            </Card>
                          )) 
                          :
                          (
                            fetching ?
                            (
                              <>
                                <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={60} />
                                <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={60} />
                              </>
                            )
                            :
                            (
                              <div className={`${s.content} ${s.no_requests}`}>
                                <p>No Requests Yet</p>
                              </div>
                            )
                          )
                        }
                      </div>
                    </Widget>
                  </Col>
                </Widget>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  )
}

export default ProgramRequests;
