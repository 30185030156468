import axios, { setHeadersAuthToken } from "../utils/axios";
import { setUser, logoutUser } from "../actions/auth";

const checkAuthentication = async (dispatch) => {
  const token = localStorage.getItem("eduems-dashboard-login-session");

  if (!token) return false;
  
  const { data: { success: user, err } } = await axios.post("/auth/validate", { token });

  if (user) {
    setHeadersAuthToken(token);

    dispatch(setUser(user));
  }

  if (err && err !== "You are already authenticated") {
    dispatch(logoutUser())
  }
}

export default checkAuthentication;
