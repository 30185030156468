import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Row, Col, Button } from "reactstrap";
import { Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Widget from "../../components/Widget/Widget.js";
import AddClientActivationModal from "./Modals/AddClientActivationModal"
import ChangeClientPasswordModal from "./Modals/ChangeClientPasswordModal"

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";

import Swal from "sweetalert2"
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";

import s from "./ManageClients.module.scss";

const ManageClients = function () {
  const history = useHistory();
  const [fetching, setFetching] = useState(true);
  const [isAddActivationsModalOpen, setIsAddActivationsModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [clientData, setClientData] = useState({});
  const [clients, setClients] = useState([]);

  const getClients = async () => {
    const { data: { success: allClients , err } } = await axios.get(`/clients`);

    if (err) return Toast({ type: 'error', content: err });

    setClients(allClients);
    setFetching(false);
  }

  useEffect(() => {
    getClients();
  }, []);

  const closeModal = () => {
    setIsAddActivationsModalOpen(false);
    setIsChangePasswordModalOpen(false);
  }

  const openAddActivationsModal = (client) => {
    setClientData(client);
    setIsAddActivationsModalOpen(true);
  }

  const openChangePasswordModal = (client) => {
    setClientData(client);
    setIsChangePasswordModalOpen(true);
  }

  const handleClientDataChange = (client) => {
    setClients(prev => {
      const allClients = [...prev]
      const idx = prev.findIndex(clnt => clnt._id === client._id);
      allClients.splice(idx, 1, client)
      return allClients;
    });
  }

  const deleteClient = async (client) => {
    const { isConfirmed } = await Swal.fire({
      html: `Are you sure you want to delete <b>${client.username}</b>'s account ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });
    
    if (isConfirmed) {
      const { data: { err } } = await axios.delete(`/clients?clientId=${client._id}`);
  
      if (err) return Toast({ type: 'error', content: err });
  
      Toast({ type: 'success', content: "Client deleted successfully" });
  
      setClients(prev => prev.filter(clnt => clnt._id !== client._id));
    }
  }

  return (
    <>
      <div className={s.top_panel}>
        <div className={s.all_clients}>
          <i className="eva eva-person-outline"></i>
          <p>All Clients <span>{ clients.length }</span></p>
        </div>
        <div className={s.add_new_client}>
          <Button onClick={() => history.push("/clients/create")}>
            <i className="eva eva-plus-circle-outline"></i>
            Create Client
          </Button>
        </div>
      </div>
      <div>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <Widget>
                  <div className={s.tableTitle}>
                    <div className="headline-2">Manage Clients</div>
                    <div className="d-flex">
                      <a href="/#"><img src={searchIcon} alt="Search"/></a>
                      <a href="/#"><img className="d-none d-sm-block" src={cloudIcon} alt="Cloud" /></a>
                      <a href="/#"><img src={printerIcon} alt="Printer" /></a>
                    </div>
                  </div>
                  <Col xs={12} className="pr-grid-col">
                    <Widget>
                      <div className={s.widgetContentBlock}>
                        <div className={`${s.content} ${s.head}`}>
                          <div className="body-3">Username</div>
                          <div className="d-none d-md-block"><span className="muted body-2 ml-3">Email Address</span></div>
                          <div className="body-2">Activations</div>
                          <div className="body-3 muted d-none d-lg-block">Registeration Date</div>
                          <div>
                            <i className="eva eva-edit-2-outline" style={{fontSize: '22px', position: 'relative', top: "5px", color: "#6b859e"}}></i>
                          </div>
                        </div>

                        {
                          clients.length ? clients.map(client => (
                            <div key={client._id} className={s.content}>
                              <div className="body-3">{client.username}</div>
                              <div className="d-none d-md-block"><span className="muted body-2 ml-3">{client.email}</span></div>
                              <div className="body-2"><span>( {client.activations.length} / {client.availableActivations} )</span></div>
                              <div className="body-3 muted d-none d-lg-block">{ dayjs(client.createdAt).format("DD/MM/YYYY") }</div>
                              <div>
                                <div>
                                  <Tooltip title="Add Activation" aria-label="Add Activation">
                                    <i className="eva eva-activity-outline" onClick={() => openAddActivationsModal(client)} />
                                  </Tooltip>
                                  <Tooltip title="Change Password" aria-label="Change Password">
                                    <i className="eva eva-unlock-outline" onClick={() => openChangePasswordModal(client)} />
                                  </Tooltip>
                                  <Tooltip title="Delete" aria-label="Delete">
                                    <i className="eva eva-trash-outline" onClick={() => deleteClient(client)} />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          )) 
                          : 
                          (
                            fetching ?
                            <>
                              <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={60} />
                              <Skeleton variant="rect" style={{ borderRadius: '10px', marginBottom: '10px' }} height={60} />
                            </>
                            :
                            <div className={`${s.content} ${s.no_clients}`}>
                              <p>No Clients Yet</p>
                            </div>
                          )
                        }
                      </div>
                    </Widget>
                  </Col>
                </Widget>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <AddClientActivationModal
        closeModal={closeModal}
        isOpen={isAddActivationsModalOpen}
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
      />
      <ChangeClientPasswordModal
        closeModal={closeModal}
        isOpen={isChangePasswordModalOpen}
        clientData={clientData}
      />
    </>
  )
}

export default ManageClients;
