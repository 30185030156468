import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, InputGroup, Input, Button, Label } from "reactstrap";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";
import cls from "./CreateClient.module.scss"

const CreateClient = () => {
  const history = useHistory();
  const [fromSubmitLoading, setFromSubmitLoading] = useState(false);
  const [clientForm, setClientForm] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    availableActivations: 0,
    role: 'client'
  });

  const handleChange = (e) => {
    setClientForm(prev => ({
      ...prev,
      [e.target.name]: e.target.name === "availableActivations" ? +e.target.value : e.target.value
    }))
  }

  const createClient = async () => {
    setFromSubmitLoading(true);

    if (clientForm.password !== clientForm.confirmPassword) {
      setFromSubmitLoading(false);
      return Toast({ type: 'error', content: "Passwords doesn't match" });
    }

    const newClient = Object.assign({}, clientForm);

    delete newClient.confirmPassword;

    const { data: { err } } = await axios.post(`/clients`, newClient);

    if (err) {
      setFromSubmitLoading(false);
      return Toast({ type: 'error', content: err });
    }

    setFromSubmitLoading(false);

    Toast({type: 'success', content: `Client Created Successfully`});

    history.push("/clients/manage");
  }
  
  return (
    <>
      <div className={cls.client}>
        <h4>Create New Client</h4>
        <Row>
          <Col xs={12} md={6}>
            <Label>Username</Label>
            <InputGroup className={cls.client__input_group}>
              <Button className={cls.client__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-person-outline"></i>
              </Button>
              <Input
                className={cls.client__input}
                placeholder="Username"
                type="text"
                name="username"
                value={clientForm.username}
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Email Address</Label>
            <InputGroup className={cls.client__input_group}>
              <Button className={cls.client__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-email-outline"></i>
              </Button>
              <Input
                className={cls.client__input}
                placeholder="Email Address"
                type="email"
                name="email"
                value={clientForm.email}
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>New Password</Label>
            <InputGroup className={cls.client__input_group}>
              <Button className={cls.client__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-unlock-outline"></i>
              </Button>
              <Input
                className={cls.client__input}
                placeholder="New Password"
                type="password"
                name="password"
                value={clientForm.password}
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Confirm Password</Label>
            <InputGroup className={cls.client__input_group}>
              <Button className={cls.client__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-unlock-outline"></i>
              </Button>
              <Input
                className={cls.client__input}
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                value={clientForm.confirmPassword}
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Label>Add Activations</Label>
            <InputGroup className={cls.client__input_group}>
              <Button className={cls.client__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-activity-outline"></i>
              </Button>
              <Input className={cls.client__input} type="select" name="availableActivations" onChange={handleChange}>
                <option value="0">
                  Don't Add Activations
                </option>
                <option value="1">
                  Add 1 Activation
                </option>
                <option value="2">
                  Add 2 Activations
                </option>
                <option value="3">
                  Add 3 Activations
                </option>
              </Input>
            </InputGroup>
          </Col>
        </Row>
        <Button onClick={createClient} disabled={fromSubmitLoading}>
          {
            fromSubmitLoading ?
            "Creating..."
            :
            "Create Client"
          }
        </Button>
      </div>
    </>
  )
}

export default CreateClient;
