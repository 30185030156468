import { useState, useEffect } from 'react'
import axios from "../../../utils/axios";
import { Toast } from "../../../components/Notification/Notification.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, InputGroup, Input } from "reactstrap"
import cls from "../ManageClients.module.scss"

const ChangeClientPasswordModal = ({ closeModal, isOpen, clientData }) => {
  const [fromSubmitLoading, setFromSubmitLoading] = useState(false)
  const [changePassForm, setChangePassForm] = useState({
    username: '',
    email: '',
    _id: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    setChangePassForm(prev => ({
      ...prev,
      username: clientData.username,
      email: clientData.email,
      _id: clientData._id
    }))
  }, [clientData])

  const handleChange = (e) => {
    setChangePassForm(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }

  
  const changePassword = async () => {
    setFromSubmitLoading(true);

    if (changePassForm.newPassword !== changePassForm.confirmPassword) {
      setFromSubmitLoading(false);
      return Toast({ type: 'error', content: "Passwords doesn't match" });
    }

    const { data: { err } } = await axios.put(`/clients/change-password?clientId=${changePassForm._id}`, {
      newPassword: changePassForm.newPassword
    });

    if (err) {
      setFromSubmitLoading(false);
      return Toast({ type: 'error', content: err });
    }

    setFromSubmitLoading(false);

    Toast({type: 'success', content: `${changePassForm.username}'s Password changed Successfully`})

    closeModal();

    setChangePassForm({
      username: '',
      email: '',
      _id: '',
      newPassword: '',
      confirmPassword: ''
    })
  }

  return (
    <Modal isOpen={isOpen} fade={true} centered size="lg" keyboard={false}>
      <ModalHeader toggle={closeModal}>
        Change {changePassForm.username}'s Password
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} md={6}>
            <Label>Username</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-person-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Username" type="text" name="username" value={changePassForm.username} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Email Address</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-email-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Email Address" type="email" name="email" value={changePassForm.email} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>New Password</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-lock-outline"></i>
              </Button>
              <Input className={cls.modal__input} placeholder="New Password" type="password" name="newPassword" value={changePassForm.newPassword} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Confirm Password</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-lock-outline"></i>
              </Button>
              <Input className={cls.modal__input} placeholder="Confirm Password" type="password" name="confirmPassword" value={changePassForm.confirmPassword} onChange={handleChange} />
            </InputGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={changePassword} disabled={fromSubmitLoading}>
          {
            fromSubmitLoading ? "Changing..." : "Change Password"
          }
        </Button>
        <Button onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ChangeClientPasswordModal
