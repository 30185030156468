import React, { useState } from "react";
import { connect } from 'react-redux';
import { Row, Col, InputGroup, Input, Button, Label } from "reactstrap";
import axios from "../../utils/axios";
import { Toast } from "../../components/Notification/Notification.js";
import cls from "./RequestActivation.module.scss"

const RequestActivation = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [requestForm, setRequestForm] = useState({
    username: user?.username,
    email: user?.email,
    title: 'Request 1 Activation',
    details: ''
  });

  const handleChange = (e) => {
    setRequestForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const sendRequest = async () => {
    setLoading(true);

    const { data: { err } } = await axios.post(`/requests/activation`, requestForm);

    if (err) {
      setLoading(false);
      return Toast({ type: 'error', content: err });
    }

    Toast({ type: 'success', content: 'Your request delivered successfully' });

    setLoading(false);

    setRequestForm({
      username: user?.username,
      email: user?.email,
      title: 'Request 1 Activation',
      details: ''
    });
  }

  return (
    <>
      <div className={cls.account}>
        <h4>Request New Activation</h4>
        <Row>
          <Col xs={12} md={6}>
            <Label>Username</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-person-outline"></i>
              </Button>
              <Input className={cls.account__input} disabled placeholder="Username" type="text" value={requestForm.username} />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Email Address</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-email-outline"></i>
              </Button>
              <Input className={cls.account__input} disabled placeholder="Email Address" type="email" value={requestForm.email} />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Label>Request Activations</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-paper-plane-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                type="select"
                name="title"
                value={requestForm.title}
                onChange={handleChange}
              >
                <option value="Request 1 Activation">
                  Request 1 Activation
                </option>
                <option value="Request 2 Activation">
                  Request 2 Activations
                </option>
                <option value="Request 3 Activation">
                  Request 3 Activations
                </option>
              </Input>
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Label>Request Details</Label>
            <InputGroup className={cls.account__input_group}>
              <Button className={cls.account__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-file-text-outline"></i>
              </Button>
              <Input
                className={cls.account__input}
                placeholder="Request Details"
                type="textarea"
                rows={5}
                style={{resize: 'none'}}
                name="details"
                value={requestForm.details}
                onChange={handleChange}
                />
            </InputGroup>
          </Col>
        </Row>
        <Button onClick={sendRequest} disabled={loading}>
          {
            loading
            ?
            "Requesting..."
            :
            "Send Request"
          }
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = (store) => ({
  user: store.auth.user
})

export default connect(mapStateToProps)(RequestActivation);
