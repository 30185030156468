import cls from "./Loading.module.scss"

const Loading = () => {
  return (
    <div className={cls.loader}>
      <div className={cls['lds-dual-ring']}></div>
    </div>
  )
}

export default Loading
