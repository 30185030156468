import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, InputGroup, Input } from "reactstrap"
import cls from "../ManageActivations.module.scss"
import axios from "../../../utils/axios";
import { Toast } from "../../../components/Notification/Notification.js";

const RegisterActivationModal = ({ toggle, isOpen, addNewActivation, availableActivations }) => {
  const [fromSubmitLoading, setFromSubmitLoading] = useState(false);
  const [activationForm, setActivationForm] = useState({
    availableActivations: 0,
    activationDate: '',
    registerationDate: dayjs(new Date()).format("DD/MM/YYYY | hh:mm a"),
    serialCode: uuidv4(),
    macAddress: '',
    statue: 'Idle',
  });

  useEffect(() => {
    if (availableActivations >= 0) {
      setActivationForm(prev => ({
        ...prev,
        availableActivations
      }))
    }
  }, [availableActivations])

  const handleChange = (e) => {
    if (e.target.name === "macAddress" && e.target.value !== '') {
      return setActivationForm(prev => ({
        ...prev,
        [e.target.name]: e.target.value,
        activationDate: dayjs(new Date()).format("DD/MM/YYYY | hh:mm a"),
        statue: "Active"
      }))
    }
    
    setActivationForm(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const registerActivation = async () => {
    setFromSubmitLoading(true);

    // Check MAC Address
    if (activationForm.macAddress !== '') {
      if (!activationForm.macAddress.toUpperCase().match(/([\da-f]{2}[:-]){5}[\da-f]{2}/i)) {
        setFromSubmitLoading(false);
        return Toast({type: 'error', content: `You entered invalid MAC Address`})
      }
    }

    // Check Available Activations
    if (activationForm.availableActivations <= 0) {
      setFromSubmitLoading(false);
      return Toast({type: 'error', content: `You don't have activations available`});
    }

    let formattedActivationForm = {};

    Object.keys(activationForm).forEach(key => {
      if (activationForm[key] && key !== 'availableActivations') formattedActivationForm[key] = activationForm[key];
    });

    const { data: { success: activation, err } } = await axios.post(`/activations`, formattedActivationForm);

    if (err) {
      setFromSubmitLoading(false);
      return Toast({ type: 'error', content: err });
    }
    
    addNewActivation(activation);

    setFromSubmitLoading(false);

    Toast({type: 'success', content: `Activation Registered Successfully`})

    toggle();

    setActivationForm({
      availableActivations: 0,
      activationDate: '',
      registerationDate: dayjs(new Date()).format("DD/MM/YYYY | hh:mm a"),
      serialCode: uuidv4(),
      macAddress: '',
      statue: 'Idle',
    })
  }

  return (
    <Modal isOpen={isOpen} fade={true} centered size="lg" keyboard={false}>
      <ModalHeader toggle={toggle}>
        Register New Activation
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} md={6}>
            <Label>Available Activations</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-activity-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Available Activations" type="number" name="availableActivations" value={activationForm.availableActivations} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Register Date</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-calendar-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Register Date" type="text" name="registerationDate" value={activationForm.registerationDate} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Label>Serial Code</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-hash-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Serial Code" type="text" name="serialCode" value={activationForm.serialCode} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Label>MAC Address</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-hard-drive-outline"></i>
              </Button>
              <Input className={cls.modal__input} placeholder="MAC Address (leave it empty if you don't want to activate it now)" name="macAddress" type="text" value={activationForm.macAddress} onChange={handleChange} />
            </InputGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => registerActivation()} disabled={fromSubmitLoading}>
          {
            fromSubmitLoading ? "Registering..." : "Register"
          }
        </Button>
        <Button onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RegisterActivationModal
