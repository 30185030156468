import { useState, useEffect } from 'react'
import axios from "../../../utils/axios";
import { Toast } from "../../../components/Notification/Notification.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, InputGroup, Input } from "reactstrap"
import cls from "../ManageClients.module.scss"

const RegisterActivationModal = ({ closeModal, isOpen, clientData, handleClientDataChange }) => {
  const [fromSubmitLoading, setFromSubmitLoading] = useState(false)
  const [addActivationsForm, setAddActivationsForm] = useState({
    addedActivations: 1,
    username: '',
    email: '',
    _id: ''
  });

  useEffect(() => {
    setAddActivationsForm(prev => ({
      ...prev,
      username: clientData.username,
      email: clientData.email,
      _id: clientData._id
    }))
  }, [clientData])

  const handleChange = (e) => {
    setAddActivationsForm(prev => ({
      ...prev,
      [e.target.name]: +e.target.value
    }));
  }

  
  const addActivation = async () => {
    setFromSubmitLoading(true);

    const { data: { success: client, err } } = await axios.put(`/clients/add-activations?clientId=${addActivationsForm._id}`, {
      activations: addActivationsForm.addedActivations
    });

    if (err) {
      setFromSubmitLoading(false);
      return Toast({ type: 'error', content: err });
    }
    
    handleClientDataChange(client);

    setFromSubmitLoading(false);

    Toast({type: 'success', content: `Activation Added To ${addActivationsForm.username} Successfully`})

    closeModal();

    setAddActivationsForm({
      addedActivations: 1,
      username: '',
      email: '',
      _id: ''
    })
  }

  return (
    <Modal isOpen={isOpen} fade={true} centered size="lg" keyboard={false}>
      <ModalHeader toggle={closeModal}>
        Add New Activation
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} md={6}>
            <Label>Username</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-person-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Username" type="text" name="username" value={addActivationsForm.username} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <Label>Email Address</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-email-outline"></i>
              </Button>
              <Input className={cls.modal__input} disabled placeholder="Email Address" type="email" name="email" value={addActivationsForm.email} onChange={handleChange} />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Label>Activations</Label>
            <InputGroup className={cls.modal__input_group}>
              <Button className={cls.modal__input_button} onClick={(e) => e.target.nextElementSibling.focus()}>
                <i className="eva eva-activity-outline"></i>
              </Button>
              <Input className={cls.modal__input} type="select" name="addedActivations" value={addActivationsForm.addedActivations} onChange={handleChange}>
                <option value="1">
                  Add 1 Activation
                </option>
                <option value="2">
                  Add 2 Activations
                </option>
                <option value="3">
                  Add 3 Activations
                </option>
              </Input>
            </InputGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => addActivation()} disabled={fromSubmitLoading}>
          {
            fromSubmitLoading ? "Adding..." : "Add Activations"
          }
        </Button>
        <Button onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RegisterActivationModal
