import React from 'react'

const SearchBarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.84442" cy="7.84442" r="5.99237" stroke="#6B859E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0122 12.3234L14.3616 14.6667" stroke="#6B859E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default SearchBarIcon;
