import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import EduLogo from "../Icons/EduLogo.js";
import cn from "classnames";

const Sidebar = (props) => {
  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true)
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false)
      }, 0);
    }
  }, [props.sidebarOpened])

  return (
    <nav className={cn(s.root, {[s.sidebarOpen]: burgerSidebarOpen})} >
      <header className={s.logo}>
        <EduLogo />
        <span className={s.title}>Edu EMS</span>
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Dashboard"
          isHeader
          iconName={<i className={'eva eva-home-outline mb-1 mr-1'}/>}
          link="/dashboard"
          index="dashboard"
        />
        <h5 className={s.navTitle}>USER MENU</h5>
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Account"
          isHeader
          iconName={<i className={'eva eva-person-outline mb-1 mr-2'}/>}
          link="/account"
          index="account"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Activations"
          isHeader
          iconName={<i className={'eva eva-activity-outline mb-1 mr-2'}/>}
          link="/activations"
          index="activations"
          childrenLinks={[
            {
              header: 'Activations', link: '/activations/manage',
            },
            {
              header: 'Request Activation', link: '/activations/request',
            }
          ]}
        />
        {
          props.user?.role === 'admin' &&
          (
            <>
              <LinksGroup
                onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                activeItem={props.activeItem}
                header="Clients"
                isHeader
                iconName={<i className={'eva eva-people-outline mb-1 mr-2'}/>}
                link="/clients"
                index="clients"
                childrenLinks={[
                  {
                    header: 'Clients', link: '/clients/manage',
                  },
                  {
                    header: 'Create Client', link: '/clients/create',
                  }
                ]}
              />
              <LinksGroup
                onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                activeItem={props.activeItem}
                header="Requests"
                isHeader
                iconName={<i className={'eva eva-shopping-bag-outline mb-1 mr-2'}/>}
                link="/requests"
                index="requests"
                childrenLinks={[
                  {
                    header: 'Program Requests', link: '/requests/program',
                  },
                  {
                    header: 'Activation Requests', link: '/requests/activations',
                  }
                ]}
              />
            </>
          )
        }
      </ul>
    </nav>
  );
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
    user: store.auth.user
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
